import { ref, onMounted, onUnmounted } from '@nuxtjs/composition-api';

export function useIsMobile() {
  const isMobile = ref(false);

  const checkIfMobile = () => {
    isMobile.value = window.innerWidth <= 1023;
  };

  onMounted(() => {
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', checkIfMobile);
  });

  return { isMobile };
}