









import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import TopBar from '~/components/TopBar/TopBar.vue';
import AppHeader from '@/components/AppHeader.vue';
import AppHeaderMobile from '@/components/AppHeaderMobile.vue';

export default defineComponent({
  name: 'LoginLayout',
  components: {
    AppHeaderMobile, AppHeader,
    TopBar,
  },
  setup() {
    const route = useRoute();
    return {
      route,
    };
  },
});
