import axios, { AxiosResponse } from 'axios';

export class ProductRepository {
  constructor() {}
  readonly baseUrlStore = process.env.VSF_STORE_URL;

  public async getAllProducts(pageInfo) {
    const cacheKey = `category_products_${pageInfo.categoryId}_${pageInfo.currentPage}_${pageInfo.pageSize}_${pageInfo.userPreview}_${pageInfo.sortField}_${pageInfo.sortValue}`;

    try {
      const cachedData = await axios.post(`${this.baseUrlStore}getRedis?key=${cacheKey}`);
      if (cachedData) {
        return JSON.parse(cachedData.data.cachedData);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération du cache:', error);
    }

    try {
      const response = await axios.post(`${this.baseUrlStore}getAllProducts`, {
        categoryId: pageInfo.categoryId,
        currentPage: pageInfo.currentPage,
        pageSize: pageInfo.pageSize,
        userPreview: pageInfo.userPreview,
        sortField: pageInfo.sortField,
        sortValue: pageInfo.sortValue,
      });

      await axios.post(`${this.baseUrlStore}setRedis?key=${cacheKey}`, { data: JSON.stringify(response.data), ttl: 86400 });
      
      return response.data;
    } catch (error) {
      throw JSON.stringify(error);
    }
  }

  // public async getProducts(pageInfo) {
  //   try {
  //     const response = await axios.post(`${this.baseUrlStore}getProducts`, {
  //       categoryId: pageInfo.categoryId,
  //       currentPage: pageInfo.currentPage,
  //       pageSize: pageInfo.pageSize,
  //       sortField: pageInfo.sortField,
  //       sortValue: pageInfo.sortValue,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     throw JSON.stringify(error);
  //   }
  // }

  // public async getProductsGraphql(pageInfo) {
  //   try {
  //     const response = await axios.post(`${this.baseUrlStore}getProductsGraphql`, {
  //       categoryId: pageInfo.categoryId,
  //       currentPage: pageInfo.currentPage,
  //       pageSize: pageInfo.pageSize,
  //       sortField: pageInfo.sortField,
  //       sortValue: pageInfo.sortValue,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     throw JSON.stringify(error);
  //   }
  // }

  public async getProductSku(sku) {
    const cacheKey = `products_sku_${sku}`;
    
    try {
      const cachedData = await axios.post(`${this.baseUrlStore}getRedis?key=${cacheKey}`);
      if (cachedData) {
        return JSON.parse(cachedData.data.cachedData); 
      }
    } catch (error) {
      console.error('Erreur lors de la récupération du cache pour le produit:', error);
    }

    try {
      const adminToken = await axios.post(`${this.baseUrlStore}getAdminToken`);
      const response = await axios.get(`${this.baseUrlStore}getProductSku?sku=${sku}&adminToken=${adminToken.data}`);

      await axios.post(`${this.baseUrlStore}setRedis?key=${cacheKey}`, { data: JSON.stringify(response.data), ttl: 86400 });

      return response.data;
    } catch (error) {
      throw JSON.stringify(error);
    }
  }
  // public async getVariantProductSku(sku) {
  //   try {
  //     const response = await axios.post(`${this.baseUrlStore}getVariantBySku`, {
  //       sku: sku,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     throw JSON.stringify(error);
  //   }
  // }
}
